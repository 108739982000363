import React, { createContext, useContext, useState, useEffect } from 'react';
import { setDir } from './store/customizer/CustomizerSlice';
import { useDispatch } from 'react-redux';
import { get, ref } from 'firebase/database';
import { database } from './book/src/firebase';
import { useAuth } from './views/authentication/AuthContext';
import SentryLogger from './components/logging/SentryLogger';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  // Use state to hold the current language
  const [language, setLanguage] = useState('en');
  const [supportedLanguages, setSupportedLanguages] = useState(['en', 'ar', 'heb']);
  const [defaultLang, setDefaultLang] = useState('en');

  const { bizId } = useAuth();

  // dispatch(setDir('ltr'));
  const dispatch = useDispatch();

  const logger = new SentryLogger();

  useEffect(() => {
    const fetchSupportedLangs = async () => {
      try {
        const dbRef = ref(database, `/biz/configs/${bizId}/supportedLanguages`);
        const snapshot = await get(dbRef);

        if (snapshot.exists()) {
          const supportedLangs = snapshot.val();
          setSupportedLanguages(Object.keys(supportedLangs));
        }
      } catch (error) {
        logger.error(error);
        console.error(error);
      }
    };

    if (bizId) {
      fetchSupportedLangs();
    }
  }, [bizId]);

  useEffect(() => {
    const fetchSupportedLangs = async () => {
      try {
        const dbRef = ref(database, `/biz/configs/${bizId}/defaultLang`);
        const snapshot = await get(dbRef);

        if (snapshot.exists()) {
          const defaultLang = snapshot.val();
          setDefaultLang(defaultLang);
        }
      } catch (error) {
        logger.error(error);
      }
    };

    if (bizId) {
      fetchSupportedLangs();
    }
  }, [bizId]);

  useEffect(() => {
    const fetchLanguage = async () => {
      const savedLanguage = localStorage.getItem('languageV2');

      // if (savedLanguage) {
      //   setLanguage(savedLanguage);
      //   dispatch(setDir(savedLanguage === 'en' ? 'ltr' : 'rtl'));
      //   return;
      // }

      if (bizId) {
        if (savedLanguage) {
          setLanguage(savedLanguage);
          dispatch(setDir(savedLanguage === 'en' ? 'ltr' : 'rtl'));
        } else {
          try {
            const dbRef = ref(database, `/biz/configs/${bizId}/defaultLang`);
            const snapshot = await get(dbRef);

            if (snapshot.exists()) {
              const defaultLanguage = snapshot.val();

              setLanguage(defaultLanguage);
              dispatch(setDir(defaultLanguage === 'en' ? 'ltr' : 'rtl'));
            }
          } catch (error) {
            console.error(error);
            logger.error(error);
          }
        }
      } else {
        if (savedLanguage) {
          setLanguage(savedLanguage);
          dispatch(setDir(savedLanguage === 'en' ? 'ltr' : 'rtl'));
        } else {
          // const browserLanguage = navigator.language.split('-')[0];
          const browserLanguage = 'en';
          setLanguage(browserLanguage);
          // localStorage.setItem('language', browserLanguage);
          // console.log(browserLanguage);
          dispatch(setDir(browserLanguage === 'en' ? 'ltr' : 'rtl'));
        }
      }
    };
    fetchLanguage();
  }, [bizId]);

  const switchLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('languageV2', lang);
    dispatch(setDir(lang === 'en' ? 'ltr' : 'rtl'));
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage, supportedLanguages, defaultLang }}>
      {children}
    </LanguageContext.Provider>
  );
};
