const translations = {
  en: {
    schedule: 'Book',

    enterPhoneForOtp: 'Enter phone number',

    phoneNumber: 'Phone',

    send: 'Send',

    enterPinForOtp: 'Enter the verification code that you received via message to ',

    chooseStaff: 'Choose staff',

    chooseService: 'Choose service',

    morning: 'Morning',

    afternoon: 'Afternoon',

    night: 'Evening', // Edited by Rawaa

    confirm: 'Book appointment',

    name: 'Full name',

    enterFullName: 'Enter full name',

    login: 'Login',

    sunday: 'Sunday',

    monday: 'Monday',

    tuesday: 'Tuesday',

    wednesday: 'Wednesday',

    thursday: 'Thursday',

    friday: 'Friday',

    saturday: 'Saturday',

    openingHours: 'Hours',

    closed: 'Closed',

    social: 'Social',

    book: 'Book',

    signIn: 'Sign In',

    signOut: 'Sign Out',

    appoitment: 'Appointment',

    appoitments: 'Appointments',

    cancel: 'Cancel',

    canceled: 'Canceled',

    at: 'at',

    reschedule: 'Reschedule',

    invalidPhone: `Invalid Phone Number. Please enter a valid format.
  
  For example: +9722345678901 or 05012345678`,

    generalError: 'An error has occurred. Please try again later.',

    appointmentError:
      'We encountered an issue while scheduling. This appointment may have been taken or passed, please try again.',

    wrongPinError:
      "PIN code entered is incorrect. Please ensure you're using the correct code sent to your phone number and try again.",

    // Start Working Hours

    workingHours: 'Working Hours',

    selectStaff: 'Select Staff member', // Edited by Rawaa

    timeZone: 'Time Zone',

    weeklyWorkingHours: 'Weekly Working Hours',

    start: 'Start',

    end: 'End',

    addShift: 'Add Shift',

    update: 'Update',

    dateSpecificHours: 'Date-Specific Hours',

    dateSpecificText:
      'Override your availability for specific dates when your hours differ from your regular weekly hours.',

    addDateSpecificHours: 'Add Date-Specific Hours',

    // Working Hours Spinner

    updating: 'Updating',

    updatingText: 'This may take a few seconds',

    stats: 'Stats',

    jerusalem: 'Jerusalem',

    open: 'Open',

    weeklyHours: 'Weekly Hours',

    datesOverrides: 'Dates Overrides',

    // Menu items

    customers: 'Customers',

    chats: 'Chats',

    appointment: 'Appointment',

    chooseCustomer: 'Choose customer',

    scheduleFor: 'Schedule For',

    staff: 'Staff member', // Edited by Rawaa

    service: 'Service',

    loginText:
      "Please enter the mobile number associated with your business account. We'll use this number to send a verification code for login confirmation.",

    typePhone: 'Type your phone number',

    twoSteps: 'Two Step Verification',

    sentOtp:
      'We sent a verification code to your mobile. Enter the code from the mobile in the field below.',

    enter6digits: 'Type your 6 digits security code',

    resend: 'Resend',

    didntRecieve: "Didn't get the code?",

    sundayFormatted: 'Sun',

    mondayFormatted: 'Mon',

    tuesdayFormatted: 'Tue',

    wednesdayFormatted: 'Wed',

    thursdayFormatted: 'Thu',

    fridayFormatted: 'Fri',

    saturdayFormatted: 'Sat',

    addCustomer: 'Add Customer',

    jane: 'Jane Smith',

    confirmAction: 'Confirm Action',

    scheduleWithoutCustomer: "You can't create an appointment without choosing a customer",

    yes: 'Yes', //Edited by Rawaa

    no: 'No',

    nope: 'Cancel',

    addNewCustomer: 'Add new customer',

    reSchedule: 'Reschedule appointment',

    molla: 'Molla',

    nameShort: 'Name',

    previousAppointment: 'Previous Appointment',

    nextAppointment: 'Next Appointment',

    searchEverything: 'Search', // Edited by R

    today: 'Today',

    tomorrow: 'Tomorrow',

    //questionnaires

    questionnaires: 'Questionnaires',

    with: 'with',

    call: 'Call',

    whatsapp: 'WhatsApp', // Edited by Rawaa

    block: 'Block',

    unblock: 'Unblock',

    lastConversation: 'Last Conversation',

    joined: 'Joined',

    status: 'Status',

    bookings: 'Bookings',

    payments: 'Payments',

    blocked: 'Blocked',

    active: 'Active',

    pending: 'Pending',

    blockConfirmAction: 'Are you sure you want to block',

    questionMark: '?',

    charge: 'Charge',

    time: 'Time',

    description: 'Description',

    price: '₪',

    cancelAppointmentDescription: 'Are you sure you want to cancel this appointment?',

    cancelAppointmentTitle: 'Cancel Appointment',

    noEventsMessage: 'No events yet',

    tag: 'Tags',

    addTag: 'Add Tag',

    modifyTag: 'Edit Tag',

    tagName: 'Tag Name',

    tagColor: 'Tag Color',

    invalidPhoneNumberTitle: 'Invalid phone number',

    invalidPhoneNumberMessage:
      'Please enter a valid phone number, ensuring it includes only numeric characters',

    ok: 'Okay',

    add: 'Add',

    noResults: 'No entries available',

    // login page general error

    loginErrorMsgTitle: 'Oops! There was an issue with your login',

    loginErrorMsgDescription: 'There is an error with your login, please try again',

    // already scheduled customer

    alreadyScheduledCustomerTitle: 'Reschedule Appointment',

    alreadyScheduledCustomerMessageP1: 'Heads up! The customer is already booked on',

    alreadyScheduledCustomerMessageP2:
      'If you need to adjust their appointment, simply proceed to reschedule',

    new: 'New',

    notifications: 'Notifications',

    notification: 'Notification',

    archive: 'Archive',

    theArchive: 'Inbox',

    selectAll: 'Select All',

    deSelectAll: 'Deselect All',

    whitelistRequest: 'Wants to be your customer',

    approve: 'Approve',

    chargeErrorTitle: 'Incomplete Invoice Details',

    chargeErrorMessage:
      'One or more products listed have no price specified. Please ensure all products have prices before proceeding with the charge.',

    invoice: 'Invoice',

    billingTo: 'BILLING TO:',

    custom: 'Custom',

    products: 'Products',

    soon: 'Soon',

    total: 'TOTAL:',

    noChargableApts: "There aren't any chargeable appointments",

    copy: 'Copy',

    qrMessage: 'Scan, Copy or Send via WhatsApp', // Edited by Rawaa

    whatsapp: 'Whatsapp',

    refundUserDeposit: "Refund user's deposit",

    selectBiz: 'Select Business',

    privacyPolicy: 'Privacy Policy',

    termsOfService: 'Terms of Service',

    needsAssistance: 'Needs Assistance',

    active: 'Active',

    slightlyActive: 'Slightly Active',

    notActive: 'Not Active',

    helpRequest: 'Wants your help',
    slighlyActiveRequestRami: 'Didnt respond to checkup yet',
    everythingIsFine: 'Everything is fine',

    helped: 'Helped',

    notes: 'Notes',

    addNote: 'Add your note',

    add: 'Add',

    profile: 'Profile',

    personalInfo: 'Personal Info',

    save: 'Save',

    edit: 'Edit',

    editQuestionnaire: 'Edit Questionnaire',

    invoiceSent: 'The invoice has been sent to the customer',

    priceWord: 'Price',

    view: 'View',

    refunded: 'Refunded',

    refund: 'Refund',

    paid: 'Paid',

    pending: 'Pending',

    outOf: ' out of ',

    refundCustomerTitle: 'Refund customer',

    refundCustomerText: 'Are you sure you want to return',

    to: 'to',

    scheduleAnotherAptTitle: 'Scheduling another appointment',

    scheduleAnotherAptText:
      'This customer already has a booking. Would you like to proceed with booking another one anyway?', // Edited by Rawaa

    product: 'Product',

    download: 'Export',

    priceName: 'Price',

    quantity: 'Inventory',

    image: 'Image',

    editProduct: 'Edit Product',

    addProduct: 'Add Product',

    inStock: 'in stock',

    customer: 'Customer',

    missingInfoTitle: 'Missing or incorrect information',

    missingProductDetails: 'There are missing info in these languages:',

    showInBot: 'Show in bot',

    bundle: 'Bundle',

    orders: 'Orders',

    canceledAt: 'Canceled at',
    completedAt: 'Completed at',

    order: 'Order',
    datePlaced: 'Date placed',

    completed: 'Completed',
    canceled: 'Canceled',
    pending: 'Pending',

    complete: 'Complete',

    sortBy: 'Sort by',

    viewPendingOrder: 'Has a pending order, click to view',

    cancelOrder: 'Are you sure you want to cancel this order?',

    qty: 'Qty',

    soldOut: 'Sold out',

    contactUsSupport: 'Contact us at Support@wats-va.com',

    outOf: 'out of',

    deleteTagWarning: 'Are you sure you want to delete this tag?',
    deleteAddOnWarning: 'Are you sure you want to delete this add-on?',
    deleteTagCategory: 'Are you sure you want to delete this category?',

    select: 'Select',

    newUsers: 'New users',
    questionnairesAns: 'Questionnaires answers',

    overview: 'Overview',

    bookingsTotalNumber: 'Total number of bookings is',
    usersTotalNumber: 'Total number of users is',
    questionnairesTotalNumber: 'Total number of questionnaires answers is',

    thisMonth: 'This month',

    ooo: 'Out of Office',
    editooo: 'Edit Out of Office',
    title: 'Title',

    loginErrorBlockMsgDescription:
      'There have been 3 or more failed login attempts. Please try again in 1 minute',

    teamMangament: 'Team Management',

    team: 'Team',

    editService: 'Edit Service',
    addService: 'Add Service',
    serviceName: 'Service Name',
    category: 'Category',
    internalName: 'Internal Name',

    downPayment: 'Down Payment',

    cost: 'Price',

    duration: 'Duration',

    minutes: 'min',

    cancelFullRefundDaysBefore: 'Full refund if canceled before',

    days: 'Days',
    general: 'General',

    hasFutureApt: 'Future appointment',
    doesntHaveFutureApt: 'No future appointment',

    atLeastOneStaff:
      'There must be at least one staff member assigned to perform this type of appointment. If you wish to hide it, you may remove the appointment.',

    charLimitMsg24: 'Character limit exceeded. WhatsApp allows a maximum of 24 characters',
    charLimitMsg60: 'Character limit exceeded. WhatsApp allows a maximum of 60 characters',

    missingOrRequired: 'Please complete all required fields, which are highlighted in red',

    addOns: 'Appointment Add-ons',

    addAppointment: 'New Appointment',

    addAddOn: 'Add on',

    addCategory: 'Add category',
    editCategory: 'Edit category',

    categoryName: 'Category name',

    addingAddOn: 'Add Add-on',
    editAddOn: 'Edit Add-on',

    deleteAptWarning:
      'This action will permanently delete the appointment. Are you sure you want to proceed?',
  },

  ar: {
    schedule: 'احجز',

    enterPhoneForOtp: 'اكتب رقم الهاتف للدخول',

    phoneNumber: 'رقم الهاتف',

    send: 'ارسل',

    enterPinForOtp: ' أدخل رمز التحقق الذي وصلك عبر رسالة الى ',

    chooseStaff: 'اختيار حلاق',

    chooseService: 'اختار الخدمة المناسبة', // Edited by Rawaa

    morning: 'الصبح',

    afternoon: 'بعد الظهر',

    night: 'المساء',

    confirm: 'احجز',

    name: 'الاسم الكامل',

    enterFullName: 'اكتب اسمك الكامل',

    login: 'دخول',

    sunday: 'الأحد',

    monday: 'الاثنين',

    tuesday: 'الثلاثاء',

    wednesday: 'الأربعاء',

    thursday: 'الخميس',

    friday: 'الجمعة',

    saturday: 'السبت',

    openingHours: 'ساعات العمل',

    closed: 'مغلق',

    social: 'سوشيال',

    book: 'احجز',

    SignIn: 'دخول',

    signOut: 'خروج',

    appoitment: 'دور',

    appoitments: 'جدول المواعيد',

    cancel: 'الغي',

    canceled: 'ملغي',

    reschedule: 'تغيير موعد',

    invalidPhone: `رقم هاتف غير صالح. الرجاء إدخال تنسيق صحيح.
  
  مثال: +9722345678901 أو 05012345678`,

    generalError: 'حدث خطأ. الرجاء المحاولة مرة أخرى لاحقًا.',

    appointmentError:
      '.واجهنا مشكلة أثناء الحجز. قد يكون هذا الموعد قد اُستُخدِم أو مضى، الرجاء المحاولة مرة أخرى',

    wrongPinError: 'الرمز السري خاطئ. تحقق من رمز التحقق وحاول مرة اخرى', // Edited by Rawaa

    // Start Working Hours

    workingHours: 'ساعات العمل',

    selectStaff: 'اختيار موظّف',

    staff: 'موظّف',

    timeZone: 'منطقة زمنية',

    weeklyWorkingHours: 'ساعات العمل الأسبوعيّة',

    start: 'بداية',

    end: 'نهاية',

    addShift: 'إضافة ورديّة',

    update: 'تعديل',

    dateSpecificHours: 'ايام عطل',

    dateSpecificText: `.تعديل ساعات العمل الخاصة بك لتواريخ محددة عندما تختلف عن الساعات الأسبوعية المعتادة`,

    addDateSpecificHours: 'إضافة يوم عُطلة',

    // Working Hours Spinner

    updating: 'تعديل',

    updatingText: 'قد يستلزم الأمر بضع ثوان.',

    stats: 'إحصائيّات',

    jerusalem: 'القدس',

    open: 'مفتوح',

    weeklyHours: 'ساعات العمل الأسبوعيّة',

    datesOverrides: 'ايام العطل',

    customers: 'الزبائن',

    chats: 'محادثات',

    appointment: 'دور',

    chooseCustomer: 'اختيار زبون',

    scheduleFor: 'احجز دور في',

    service: 'خدمة',

    loginText:
      'الرجاء إدخال رقم الهاتف المحمول المرتبط بحساب عملك. سنستخدم هذا الرقم لإرسال رمز التحقق لتأكيد تسجيل الدخول.',

    typePhone: 'اكتب رقم هاتفك',

    twoSteps: 'التحقّق بخطوتين',

    sentOtp: 'أرسلنا رمز التحقق إلى هاتفك المحمول. أدخل الرمز من الهاتف المحمول في الحقل أدناه.',

    enter6digits: 'اكتب رمز الأمان المكون من 6 أرقام',

    resend: 'إعادة إرسال',

    didntRecieve: 'لم تتلق الرمز؟',

    sundayFormatted: 'الأحد',

    mondayFormatted: 'الإثنين',

    tuesdayFormatted: 'الثلاثاء',

    wednesdayFormatted: 'الأربعاء',

    thursdayFormatted: 'الخميس',

    fridayFormatted: 'الجمعة',

    saturdayFormatted: 'السبت',

    addCustomer: 'اضافة زبون',

    jane: 'رامي سعد',

    confirmAction: 'تأكيد الإجراء',

    scheduleWithoutCustomer: 'هل أنت متأكد أنك تريد تحديد موعد دون اختيار موظّف؟', // Editd by Rawaa

    yes: 'نعم',

    no: 'لا',

    nope: 'إلغاء',

    addNewCustomer: 'اضافة زبون جديد', // Editd by Rawaa

    reSchedule: 'تأجيل الموعد',

    molla: 'Molla',

    nameShort: 'الاسم',

    previousAppointment: 'اخر زيارة',

    nextAppointment: 'الموعد القادم',

    searchEverything: 'ابحث',

    at: 'عالساعة',

    today: 'اليوم',

    tomorrow: 'بكرا',

    questionnaires: 'استبيانات',

    editQuestionnaire: 'تعديل استبيان',

    with: 'مع',

    call: 'اتصل',

    whatsapp: 'واتساب',

    block: 'بلوك', // Editd by Rawaa

    unblock: 'فك البلوك', // Editd by Rawaa

    lastConversation: 'اخر محادثة',

    joined: 'انضم',

    status: 'الحالة',

    bookings: 'الحجوزات',

    payments: 'المدفوعات',

    blocked: 'محطوط بلوك', // Editd by Rawaa

    active: 'فعّال',

    pending: 'قيد الانتظار',

    blockConfirmAction: ' متأكد بدك تحط بلوك؟', // Editd by Rawaa

    questionMark: '؟',

    charge: 'تدفيع',

    time: 'الوقت',

    description: 'وصف',

    price: '₪',

    priceName: 'السعر',

    cancelAppointmentDescription: 'هل أنت متأكد من أنك تريد إلغاء هذا الموعد؟',

    cancelAppointmentTitle: 'إلغاء الموعد',

    noEventsMessage: 'لا يوجد حجوزات حاليا',

    tag: 'Tag', //Edited by Rawaa

    addTag: 'اضافة tag', //Edited by Rawaa

    tagName: 'اسم ال tag', //Edited by Rawaa

    tagColor: 'لون التاچ', //Edited by Rawaa

    invalidPhoneNumberTitle: 'رقم هاتف غير صالح',

    invalidPhoneNumberMessage: 'يرجى التأكد من إدخال رقم هاتف صحيح يتكون من أرقام فقط',

    ok: 'تمام',

    add: 'اضافة',

    noResults: 'لا توجد بيانات متاحة',

    // login page general error

    loginErrorMsgTitle: 'عذرًا! هناك مشكلة في تسجيل دخولك',

    loginErrorMsgDescription: 'هنالك مشكلة في تسجيل دخولك، يرجى المحاولة مجددًا',

    alreadyScheduledCustomerTitle: 'إعادة الجدولة',

    alreadyScheduledCustomerMessageP1: 'ملاحظة! للزبون دور بالفعل يوم', // Edited by Rawaa

    alreadyScheduledCustomerMessageP2:
      'إذا كنت بحاجة لتعديل الموعد، يرجى الانتقال إلى إعادة الجدولة',

    new: 'جديد',

    notifications: 'إشعارات',

    notification: 'إشعار',

    archive: 'أرشيف',

    theArchive: 'الأرشيف',

    selectAll: 'اختر الكل',

    deSelectAll: 'الغاء تحديد الكل',

    whitelistRequest: 'بده يصير زبون عندك', // Edited by Rawaa

    approve: 'موافقة',

    chargeErrorTitle: 'تفاصيل الفاتورة غير كاملة',

    chargeErrorMessage:
      'تم تحديد منتج أو أكثر بدون سعر. يرجى التأكد من تحديد الأسعار لجميع المنتجات قبل المتابعة في عملية الدفع',

    invoice: 'فاتورة',

    billingTo: 'إرسال فاتورة إلى',

    custom: 'مشخّص',

    products: 'منتجات',

    soon: 'قريباً',

    total: 'المجموع',

    noChargeableApts: 'لا توجد مواعيد برسوم',

    copy: 'انسخ',

    qrMessage: 'انسخ أو أرسل عبر الواتساب',

    whatsapp: 'واتساب',

    refundUserDeposit: 'ترجيع إيداع المستخدم',

    selectBiz: 'اختيار مشروع',

    privacyPolicy: 'سياسة الخصوصية',

    termsOfService: 'شروط الخدمة',

    outOf: ' من ',

    needsAssistance: 'بحاجة لاعتناء',

    active: 'فعّال',

    slightlyActive: 'فعّال جزئياً',

    notActive: 'غير فعّال',

    helpRequest: 'بدو مساعدة منك',

    helped: 'ساعدته', // Edited by Rawaa

    notes: 'ملاحظات',

    addNote: 'اضف ملاحظة',

    add: 'اضافة',

    profile: 'حساب تعريفي',

    personalInfo: 'معلومات شخصية',

    save: 'احفظ',

    edit: 'تعديل',

    invoiceSent: 'تم إرسال الفاتورة إلى الزبون', // Edited by Rawaa

    scheduleAnotherAptTitle: 'جدولة موعد آخر',

    scheduleAnotherAptText: 'لهذاالزبون موعد محجوز. بدك تحجز موعد اخر؟', // Edited by Rawaa

    priceWord: 'السعر',

    view: 'مشاهدة',

    refunded: 'ارجاع', // Edited by Rawaa

    refund: 'ترجيع',

    paid: 'مدفوع',

    pending: 'قيد الانتظار',

    refundCustomerTitle: 'ترجيع للزبون', // Edited by Rawaa

    refundCustomerText: 'هل أنت متأكد من أنك تريد إعادة',

    to: 'الى',

    product: 'منتج',

    download: 'تحميل',

    quantity: 'الكمية', // Edited by Rawaa

    image: 'صورة',

    editProduct: 'تعديل منتج',

    addProduct: 'اضافة منتج',

    inStock: 'في المخزن',

    customer: 'زبون',

    missingProductDetails: 'هناك معلومات مفقودة في هذه اللغات:',
    missingInfoTitle: 'معلومات ناقصة او مغلوطة',

    showInBot: 'فرجيه بالبوط',

    bundle: 'باقة',

    // Orders start
    orders: 'الطلبات',
    canceledAt: 'ألغي في',
    completedAt: 'اكتمل في',

    order: 'طلب',
    datePlaced: 'تاريخ الطلب',

    completed: 'مكتمل',
    canceled: 'ملغى',
    pending: 'قيد الانتظار',

    complete: 'اكمل',

    sortBy: 'رتب حسب',

    viewPendingOrder: 'لديه طلب في انتظار، انقر للعرض',

    cancelOrder: 'هل أنت متأكد أنك تريد إلغاء هذا الطلب؟',

    qty: 'كمية',

    soldOut: 'نفذت الكمية',

    contactUsSupport: 'تواصل معنا على  Support@wats-va.com',

    outOf: 'من',

    modifyTag: 'تعديل Tag',

    ooo: 'خارج المكتب',
    editooo: 'تعديل خارج المكتب',

    title: 'العنوان',

    deleteTagWarning: 'هل أنت متأكد من أنك تريد حذف هذه الTag؟',
    deleteCategoryWarning: 'هل أنت متأكد من أنك تريد حذف هذه الفئة',
    deleteAddonWarning: 'هل أنت متأكد من أنك تريد حذف هذه الاضافة',

    select: 'اختيار',

    newUsers: 'مستخدمون جدد',
    questionnairesAns: 'أجوبة الاستبيانات',

    overview: 'ملخص',

    usersTotalNumber: 'عدد المستخدمين الكلّي هو',
    bookingsTotalNumber: 'عدد الحجوزات الكلّي هو',
    questionnairesTotalNumber: 'عدد اجوبة الاستبيانات الكلّي هو',
    thisMonth: 'هذا الشهر',

    loginErrorBlockMsgDescription:
      'لقد حاولت تسجيل الدخول ثلاث مرات أو أكثر بدون نجاح. يرجى المحاولة مجددًا بعد دقيقة واحدة',
    teamMangament: 'ادارة الفريق',

    team: 'الفريق',

    editService: 'تعديل الخدمة',
    addService: 'اضافة خدمة',
    serviceName: 'اسم الخدمة',
    category: 'فئة',
    internalName: 'الاسم الداخلي',

    downPayment: 'عربون',

    cost: 'السعر',

    duration: 'مدة',
    slighlyActiveRequestRami: 'بعدو مش رادد على المحقق كونان',
    everythingIsFine: 'كلو تمام',

    minutes: 'دق',

    cancelFullRefundDaysBefore: 'استرداد كامل إذا تم الإلغاء قبل',
    days: 'ايام',

    general: 'الكل',

    hasFutureApt: 'موعد قادم',
    doesntHaveFutureApt: 'فش موعد قادم',

    atLeastOneStaff:
      'يجب تعيين على الأقل موظف واحد لأداء هذا النوع من المواعيد. إذا كنت ترغب في إخفائه، يمكنك إزالة الموعد.',

    charLimitMsg24: 'تم تجاوز الحد الأقصى لعدد الأحرف. واتساب يسمح بحد أقصى 24 حرفًا',
    charLimitMsg60: 'تم تجاوز الحد الأقصى لعدد الأحرف. واتساب يسمح بحد أقصى 60 حرفًا',
    missingOrRequired: 'يرجى إكمال جميع الحقول المطلوبة، والتي تم تمييزها باللون الأحمر',

    addOns: 'اضافة للدور',

    addAppointment: 'دور جديد',

    addAddOn: 'اضافة للدور',

    addCategory: 'اضافة فئة',
    editCategory: 'تعديل فئة',

    categoryName: 'اسم الفئة',

    addingAddOn: 'اضافة اضافة-للدور',
    editAddOn: 'تعديل اضافة للدور',

    deleteAptWarning: 'هذا الإجراء سيحذف الموعد نهائيًا. هل أنت متأكد أنك تريد المتابعة؟',
  },

  heb: {
    schedule: 'קביעת תור', // Edited by Rawaa

    enterPhoneForOtp: 'הכנס/י מספר טלפון נייד',

    phoneNumber: 'טלפון נייד',

    send: 'שלח/י', // Edited by Rawaa

    enterPinForOtp: ' הכנס/י את קוד האימות שהתקבל בהודעה אל', // Edited by Rawaa

    chooseStaff: 'בחירת עובד', // Edited by Rawaa

    chooseService: 'בחירת שירות',

    morning: 'בוקר',

    afternoon: 'אחר הצהריים', // Edited by Rawaa

    night: 'ערב', // Edited by Rawaa

    confirm: 'קביעת תור', // Edited by Rawaa

    name: 'שם מלא',

    enterFullName: 'הזנת שם מלא', // Edited by Rawaa

    login: 'כניסה',

    sunday: 'יום ראשון',

    monday: 'יום שני',

    tuesday: 'יום שלישי',

    wednesday: 'יום רביעי',

    thursday: 'יום חמישי',

    friday: 'יום שישי',

    saturday: 'שבת',

    openingHours: 'שעות פעילות',

    closed: 'סגור',

    social: 'סושיאל',

    book: 'הזמנת תור', // Edited by Rawaa

    SignIn: 'כניסה',

    appoitment: 'תור',

    appoitments: 'תורים',

    cancel: 'ביטול',

    canceled: 'בוטל',

    at: 'בשעה',

    signOut: 'התנתק',

    reschedule: 'שינוי תור',

    invalidPhone: `מספר טלפון לא חוקי. אנא הכנס/י פורמט תקין. // Edited by Rawaa
  
  לדוגמה: +9722345678901 או 05012345678`,

    generalError: 'אירעה שגיאה. אנא נסו שנית מאוחר יותר.', // Edited by Rawaa

    appointmentError: 'נתקלנו בבעיה במהלך תיאום הפגישה. ייתכן שהתור כבר תפוס או עבר, אנא נסו שוב.', // Edited by Rawaa

    wrongPinError: 'הוזן קוד זיהוי שגוי. אנא בדקו את קוד האימות ונסה שנית.', // Edited by Rawaa

    // Start Working Hours

    workingHours: 'שעות פעילות',

    selectStaff: 'בחירת חבר/ת צוות', // Edited by Rawaa

    staff: 'חבר/ת צוות', // Edited by Rawaa

    timeZone: 'אזור זמן',

    weeklyWorkingHours: 'שעות עבודה שבועיות',

    start: 'התחלה',

    end: 'סיום',

    addShift: 'הוספת משמרת',

    update: 'עדכון',

    dateSpecificHours: 'ימי חופש / חריגים',

    dateSpecificText:
      'שנה/י את הזמינות שלך לתאריכים ספציפיים במקרים בהם השעות שלך חורגות ממסגרת שעות העבודה השבועית הרגילה שלך.', // Edited by Rawaa

    addDateSpecificHours: 'הוספת ימי חופש', // Edited by Rawaa

    // Working Hours Spinner

    updating: 'מעדכן',

    updatingText: 'זה עשוי לקחת מספר שניות.',

    stats: 'סטטיסטיקה',

    jerusalem: 'ירושלים', // Edited by Rawaa

    open: 'פתוח',

    weeklyHours: 'שעות עבודה שבועיות',

    datesOverrides: 'ימי חופש',

    customers: 'לקוחות',

    chats: "צ'אט",

    appointment: 'תור',

    scheduleFor: 'קביעת תור ל',

    service: 'שירותים',

    chooseCustomer: 'לקוח/ה', // Edited by Rawaa

    loginText:
      'אנא הזנו את מספר הנייד המקושר לחשבון העסקי שלך. נשתמש במספר זה כדי לשלוח קוד אימות לאישור התחברות.', // Edited by Rawaa

    typePhone: 'הקלד את מספר הטלפון שלך',

    twoSteps: 'אימות דו-שלבי',

    sentOtp: 'שלחנו קוד אימות לנייד שלך. הכנס את הקוד מהנייד בשדה מטה.', // Edited by Rawaa

    enter6digits: 'הקלד/י את קוד האבטחה בן 6 ספרות', // Edited by Rawaa

    resend: 'שלח שוב',

    didntRecieve: 'לא קיבלת את הקוד?',

    sundayFormatted: 'ראשון',

    mondayFormatted: 'שני',

    tuesdayFormatted: 'שלישי',

    wednesdayFormatted: 'רביעי',

    thursdayFormatted: 'חמישי',

    fridayFormatted: 'שישי',

    saturdayFormatted: 'שבת',

    addCustomer: 'הוספת לקוח/ה', // Edited by Rawaa

    jane: 'שקד יצחק',

    confirmAction: 'אשר פעולה',

    scheduleWithoutCustomer: 'בטוחים שאתם רוצים לקבוע פגישה ללא בחירת לקוח/ה?', // Edited by Rawaa

    yes: 'כן',

    no: 'לא',

    nope: 'בטל',

    addNewCustomer: 'לקוח/ה חדש', // Edited by Rawaa

    reSchedule: 'שינוי תור', // Edited by Rawaa

    molla: 'Molla',

    nameShort: 'שם',

    previousAppointment: 'ביקור אחרון',

    nextAppointment: 'הביקור הבא',

    searchEverything: 'חיפוש',

    today: 'היום',

    tomorrow: 'מחר',

    questionnaires: 'שאלונים',

    with: 'עם',

    call: 'התקשר',

    whatsapp: 'וואטסאפ',

    block: 'חסימה',

    lastConversation: 'שיחה אחרונה',

    joined: 'הצטרף',

    status: 'סטאטוס',

    bookings: 'הזמנות',

    payments: 'תשלומים',

    blocked: 'חסום',

    unblock: 'ביטול חסימה',

    active: 'פעיל',

    pending: 'ממתין',

    blockConfirmAction: 'בטוח/ה שאת/ה רוצה לחסום את', // Edited by Rawaa

    questionMark: '?',

    charge: 'חייב',

    time: 'זמן',

    description: 'תיאור',

    price: '₪',

    cancelAppointmentDescription: 'האם את/ה בטוח/ה שברצונך לבטל את התור הזה?', // Edited by Rawaa

    cancelAppointmentTitle: 'ביטול תור',

    noEventsMessage: 'אין תורים',

    tag: 'תגים',

    addTag: 'הוספת תגית',

    tagName: 'שם תגית',

    tagColor: 'צבע תגית',

    invalidPhoneNumberTitle: 'מספר לא תקין',

    invalidPhoneNumberMessage: 'אנא הכנס/י מספר טלפון תקין המורכב מספרות בלבד', // Edited by Rawaa

    ok: 'אישור',

    add: 'הוספה',

    noResults: 'אין תוצאות להצגה',

    // login page general error

    loginErrorMsgTitle: 'אופס! הייתה בעיה עם הכניסה שלך',

    loginErrorMsgDescription: 'יש בעיה עם ההתחברות שלך, אנא נסו שנית',

    alreadyScheduledCustomerTitle: 'קביעת מועד חדש',

    alreadyScheduledCustomerMessageP1: 'שימו לב! הלקוח/ה כבר נקבע ליום', // Edited by Rawaa

    alreadyScheduledCustomerMessageP2: 'אם תרצה/י לשנות את התור, פשוט המשך/י לקביעת מועד חדש', // Edited by Rawaa

    new: 'חדש',

    notifications: 'התראות',

    notification: 'התראה',

    archive: 'ארכיון',

    theArchive: 'הארכיון',

    selectAll: 'בחר הכל',

    deSelectAll: 'הסר סימון מהכל',

    whitelistRequest: 'מעוניין להיכנס לרשימת הלקוחות שלך',

    approve: 'אישור',

    chargeErrorTitle: 'פרטי החשבונית אינם מלאים',

    chargeErrorMessage:
      'מוצר אחד או יותר מפורטים ללא מחיר. אנא ודא/י שלכל המוצרים יש מחירים לפני המשך בתהליך החיוב', // Edited by Rawaa

    invoice: 'חשבונית',

    billingTo: 'חשבון ל',

    custom: 'מותאם אישית',

    products: 'מוצרים',

    soon: 'בקרוב',

    total: 'סה"כ',

    noChargeableApts: 'אין תורים שניתן לחייב עבורם',

    copy: 'העתק',

    qrMessage: 'סריקה, העתקה או שליחה דרך וואטסאפ', // Edited by Rawaa

    whatsapp: 'וואטסאפ',

    refundUserDeposit: 'החזרת הפקדת המשתמש',

    selectBiz: 'בחירת עסק',

    privacyPolicy: 'מדיניות הפרטיות',

    needsAssistance: 'צריך התייחסות',

    active: 'פעיל/ה', // Edited by Rawaa

    slightlyActive: 'פעיל/ה חלקית', // Edited by Rawaa

    notActive: 'לא פעיל/ה', // Edited by Rawaa

    helpRequest: 'רוצה את עזרתך',

    helped: 'עזרתי',

    termsOfService: 'תנאי השירות',

    notes: 'הערות',

    addNote: 'הוספת הערה',

    add: 'הוספה',

    profile: 'פרופיל',

    personalInfo: 'מידע אישי',

    save: 'שמור',

    edit: 'ערוך',

    editQuestionnaire: 'עריכת שאלון',

    invoiceSent: 'החשבונית נשלחה ללקוח/ה', // Edited by Rawaa

    scheduleAnotherAptTitle: 'תזמון תור נוסף',

    scheduleAnotherAptText:
      'ברצוננו ליידע אותך שללקוח/ה זה/ו כבר יש תור. את/ה עדיין יכול לתזמן תור נוסף', // Edited by Rawaa

    priceName: 'מחיר',

    priceWord: 'מחיר',

    view: 'צפייה', // Edited by Rawaa

    paid: 'שולם',

    refunded: 'החזר',

    refund: 'החזר',

    pending: 'בהמתנה',

    outOf: ' מתוך ',

    refundCustomerTitle: 'החזר ללקוח/ה', // Edited by Rawaa

    refundCustomerText: 'האם את/ה בטוח/ה שברצונך להחזיר', // Edited by Rawaa

    to: 'ל',

    product: 'מוצר',

    customer: 'לקוח/ה', // Edited by Rawaa

    download: 'יצוא',

    quantity: 'מלאי',

    image: 'תמונה',

    editProduct: 'עריכת מוצר',

    addProduct: 'הוספת מוצר',

    inStock: 'במלאי',

    missingProductDetails: 'חסרים פרטים בשפות הבאות:',
    missingInfoTitle: 'חסר מידע',

    showInBot: 'מופיע בבוט',

    bundle: 'חבילה',
    orders: 'הזמנות',

    canceledAt: 'בוטל ב',
    completedAt: 'הושלם ב',

    order: 'הזמנה',
    datePlaced: 'תאריך הזמנה',

    completed: 'הושלם',
    canceled: 'בוטל',
    pending: 'ממתין',

    complete: 'השלם',

    sortBy: 'מיין לפי',

    viewPendingOrder: 'יש הזמנה ממתינה, לחץ לצפייה',

    cancelOrder: 'האם אתה בטוח שאתה רוצה לבטל את ההזמנה הזו?',

    qty: 'כמות',

    soldOut: 'אזל מהמלאי',

    contactUsSupport: 'צור קשר ב Support@wats-va.com',

    outOf: 'מתוך',

    modifyTag: 'עריכת תגית',

    ooo: 'מחוץ למשרד',
    editooo: 'עריכת מחוץ למשרד',
    title: 'כותרת',

    deleteTagWarning: 'האם אתה בטוח שברצונך למחוק תג זה?',
    deleteAddOnWarning: 'האם אתה בטוח שברצונך למחוק תוספת זה?',
    deleteCategoryWarning: 'האם אתה בטוח שברצונך למחוק קטגוריות זה?',

    select: 'בחירה',

    newUsers: 'לקוחות חדשים',
    questionnairesAns: 'תשובות על שאלונים',

    overview: 'סקירה כללית',

    usersTotalNumber: 'מספר המשתמשים הכולל הוא',
    bookingsTotalNumber: 'מספר התורים הכולל הוא',
    questionnairesTotalNumber: 'מספר התשובות לשאלונים הכולל הוא',
    thisMonth: 'חודש זה',

    loginErrorBlockMsgDescription: 'היו שלושה ניסיונות כניסה כושלים או יותר. אנא נסה שנית בעוד דקה',

    slighlyActiveRequestRami: 'Didnt respond to checkup yet',

    everythingIsFine: 'הכל בסדר',
    teamMangament: 'ניהול צוות',

    team: 'צוות',

    editService: 'עריכת השירות',
    serviceName: 'שם השירות',
    category: 'קטגוריה',
    internalName: 'שם פנימי',

    downPayment: 'מקדמה',

    cost: 'מחיר',

    duration: 'משך התור',

    addService: 'הוספת שירות',
    minutes: 'דק',

    cancelFullRefundDaysBefore: 'החזר מלא אם בוטל לפני',

    days: 'ימים',

    general: 'הכל',

    hasFutureApt: 'תור עתידי',
    doesntHaveFutureApt: 'אין תור עתידי',

    atLeastOneStaff:
      'חייב להיות לפחות עובד אחד מוקצה לביצוע סוג זה של תור. אם ברצונך להסתיר אותו, תוכל להסיר את התור.',

    charLimitMsg24: 'חריגה ממגבלת התווים. וואטסאפ מאפשרת מקסימום של 24 תווים',
    charLimitMsg60: 'חריגה ממגבלת התווים. וואטסאפ מאפשרת מקסימום של 60 תווים',

    missingOrRequired: 'אנא מלא את כל השדות הנדרשים, אשר מודגשים באדום',

    addOns: 'תוספת לתור',

    addAppointment: 'תור חדש',

    addAddOn: 'תוספת',

    addCategory: 'הוספת קטגוריה',
    editCategory: 'עריכת קטגוריה',

    categoryName: 'שם הקטגוריה',

    editAddOn: 'עריכת תוספת לתור',
    addingAddOn: 'הוספת תוספת לתור',

    deleteAptWarning: 'פעולה זו תמחק את התור לצמיתות. האם אתה בטוח שברצונך להמשיך?',
  },
};

export default translations;
