import React, { createContext, useContext, useEffect, useState } from 'react';
import { ref, onValue, get, set, remove, off, update, push } from 'firebase/database';
import { useAuth } from 'src/views/authentication/AuthContext';
import { database } from 'src/book/src/firebase';
import { NestCamWiredStandTwoTone } from '@mui/icons-material';
import { executeApiRequest } from 'src/book/src/utilities/utilities';
import { apiBaseUrl } from 'src/getConfig';

const BusinessSettingsContext = createContext();

export const BusinessSettingsProvider = ({ children }) => {
  const { bizId, user } = useAuth();
  const [aptServicesActive, setAptServicesActive] = useState([]);
  const [aptServicesActiveSnapshot, setAptServicesActiveSnapshot] = useState({});
  const [categories, setCategories] = useState([]);

  const [addOns, setAddOns] = useState([]);

  const timeAdded = new Date();

  const fetchAptServices = async () => {
    // fetch
    // parse
    // format
    // assign
  };

  const changeAptRanks = async (newApts) => {
    const servicesRankUpdated = aptServicesActiveSnapshot;

    const newCategoriesRank = newApts.map((category, index) => ({
      ...category,
      rank: index + 1,
    }));

    for (const newCategoryRank of newCategoriesRank) {
      servicesRankUpdated[newCategoryRank.id].rank = newCategoryRank.rank;
    }

    const categoriesRef = ref(database, `/biz/configs/${bizId}/bizApts`);

    try {
      await update(categoriesRef, {
        active: servicesRankUpdated,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAptDetails = (aptId) => {
    if (!aptId) return null;

    if (aptServicesActiveSnapshot[aptId]) {
      return aptServicesActiveSnapshot[aptId];
    }

    return null;
  };

  // Function to fetch active notifications
  useEffect(() => {
    if (!bizId) {
      setAptServicesActive([]);
      setAptServicesActiveSnapshot({});

      return;
    }

    const servicesRef = ref(database, `biz/configs/${bizId}/bizApts`);

    const onValueChange = onValue(
      servicesRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const snapshotValue = snapshot.val();

          const activeServicesSnapshot = snapshotValue.active ?? null;

          const activeServicesParsed = Object.entries(activeServicesSnapshot).map(
            ([key, value]) => ({
              id: key,
              ...value,
            }),
          );

          const sortedServices = activeServicesParsed.sort((a, b) => {
            // Assume a large number for missing ranks
            const rankA = a.rank || Number.MAX_SAFE_INTEGER;
            const rankB = b.rank || Number.MAX_SAFE_INTEGER;

            return rankA - rankB;
          });
          // notificationsArray.sort((b, a) => parseInt(a.time) - parseInt(b.time));

          setAptServicesActive(sortedServices);
          setAptServicesActiveSnapshot(activeServicesSnapshot);
        } else {
          setAptServicesActive([]);
          setAptServicesActiveSnapshot({});
        }
      },
      (error) => {
        console.error('Firebase read failed: ', error);
        setAptServicesActiveSnapshot({});
        setAptServicesActive([]);
      },
    );

    // Return function to clean up the subscription
    return () => {
      off(servicesRef, 'value', onValueChange);
    };
  }, [bizId]);

  const cleanObject = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== undefined && v !== null && !Number.isNaN(v)),
    );
  };

  // Function to fetch appointment categories
  useEffect(() => {
    if (!bizId) {
      setCategories([]);
      return;
    }

    const categoriesRef = ref(database, `biz/configs/${bizId}/aptCategories`);

    const onValueChange = onValue(
      categoriesRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const snapshotValue = snapshot.val();

          const aptCategoriesSnapshot = snapshotValue ?? null;

          const categoriesParsed = Object.entries(aptCategoriesSnapshot).map(([key, value]) => ({
            id: key,
            ...value,
          }));
          // notificationsArray.sort((b, a) => parseInt(a.time) - parseInt(b.time));

          setCategories(categoriesParsed);
        } else {
          setCategories([]);
        }
      },
      (error) => {
        console.error('Firebase read failed: ', error);
        setCategories([]);
      },
    );

    return () => {
      off(categoriesRef, 'value', onValueChange);
    };
  }, [bizId]);

  const getCategory = (categoryId) => {
    const category = categories.filter((cat) => cat.id === categoryId);
    if (category.length === 1) {
      return category[0];
    }
    return null;
  };

  const getAddOn = (addOnId) => {
    const addOn = addOns.filter((add) => add.id === addOnId);
    if (addOn.length === 1) {
      return addOn[0];
    }
    return null;
  };

  // Function to fetch Add ons
  useEffect(() => {
    if (!bizId) {
      setAddOns([]);
      return;
    }

    const addOnsRef = ref(database, `biz/configs/${bizId}/addOns`);

    const onValueChange = onValue(
      addOnsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const snapshotValue = snapshot.val();

          const addOnsSnapshot = snapshotValue ?? null;

          const addOnsParsed = Object.entries(addOnsSnapshot).map(([key, value]) => ({
            id: key,
            strings: value.addOnName,
            ...value,
          }));

          setAddOns(addOnsParsed);
        } else {
          setAddOns([]);
        }
      },
      (error) => {
        console.error('Firebase read failed: ', error);
        setAddOns([]);
      },
    );

    return () => {
      off(addOnsRef, 'value', onValueChange);
    };
  }, [bizId]);

  const editAptCategories = async (aptId, newCategoryIds) => {
    const aptPath = `biz/configs/${bizId}/bizApts/active/${aptId}/`;
    const aptRef = ref(database, aptPath);

    let aptData = {
      categoryIds: newCategoryIds,
    };

    try {
      await update(aptRef, aptData);
    } catch (e) {
      console.log(e);
    }
  };

  const addEditAptService = async (
    durationMins,
    title,
    internalTitle,
    description,
    price,
    downPaymentCost,
    cancelFullRefundDaysBefore,
    addOnIdsOptions,
    categoryIds,
    serviceId = null,
    aptDetials = null,
  ) => {
    const aptPath = `biz/configs/${bizId}/bizApts/active/${serviceId ? serviceId : ''}`;

    const aptRef = ref(database, aptPath);
    const now = new Date();

    let aptData = {
      durationMins,
      lastUpdatedTimestamp: timeAdded.getTime(),
      timestampAdded: aptDetials?.timestampAdded ?? now.getTime(),
      title,
      addOnIdsOptions,
      internalTitle,
      cancelFullRefundDaysBefore: parseInt(cancelFullRefundDaysBefore),
      description,
      downPaymentCost: parseInt(downPaymentCost),
      price: parseInt(price),
      categoryIds,
    };

    aptData = cleanObject(aptData);

    if (serviceId) {
      try {
        await update(aptRef, aptData);
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        aptData.timestampAdded = timeAdded.getTime();
        await push(aptRef, aptData);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const editAptStaffMembers = async (newStaffMembers, aptId, currentStaffMembers) => {
    // iterate over the new staff Members, and Add the ones which dont appear in old members.
    for (const staff of newStaffMembers) {
      if (currentStaffMembers.some((currentMember) => currentMember.id === staff.id)) {
        // new staff already exist, nothing to do:
        continue;
      } else {
        const response = await executeApiRequest(
          `${apiBaseUrl}/staffAptOptionChange`,
          'POST',
          {
            bizId: bizId,
            staffId: staff.id,
            aptId: aptId,
            operationType: 'Add',
          },
          user.accessToken,
        );
      }
    }

    // iterate over the old members, and remove the ones which dont appear in new staff members.
    for (const staff of currentStaffMembers) {
      if (newStaffMembers.some((currentMember) => currentMember.id === staff.id)) {
        continue;
      } else {
        const response = await executeApiRequest(
          `${apiBaseUrl}/staffAptOptionChange`,
          'POST',
          {
            bizId: bizId,
            staffId: staff.id,
            aptId: aptId,
            operationType: 'Remove',
          },
          user.accessToken,
        );
      }
    }
  };

  const getAptCategories = (categoriesIds) => {};

  const addEditCategory = async (title, description, rank, color, categoryId = null) => {
    const aptPath = `biz/configs/${bizId}/aptCategories/${categoryId ? categoryId : ''}`;

    const aptRef = ref(database, aptPath);
    const now = new Date();

    let categoryData = {
      title,
      description,
      rank,
      color,
    };

    categoryData = cleanObject(categoryData);

    if (categoryId) {
      try {
        await update(aptRef, categoryData);
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        // aptData.timestampAdded = timeAdded.getTime();
        await push(aptRef, categoryData);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const addEditAddOn = async (title, downPaymentCost, durationMins, addOnId = null) => {
    const aptPath = `biz/configs/${bizId}/addOns/${addOnId ? addOnId : ''}`;

    const aptRef = ref(database, aptPath);
    const now = new Date();

    let categoryData = {
      addOnName: title,
      downPaymentCost,
      durationMins,
    };

    categoryData = cleanObject(categoryData);

    if (addOnId) {
      try {
        await update(aptRef, categoryData);
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        // aptData.timestampAdded = timeAdded.getTime();
        await push(aptRef, categoryData);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const archiveAptService = async (serviceId) => {
    const activePath = `biz/configs/${bizId}/bizApts/active/${serviceId}`;
    const archivePath = `biz/configs/${bizId}/bizApts/archive/${serviceId}`;
    const aptRef = ref(database, activePath);
    const archiveRef = ref(database, archivePath);

    try {
      const snapshot = await get(aptRef);
      if (snapshot.exists()) {
        let aptData = snapshot.val();
        aptData.archiveTimestamp = new Date().getTime();

        await set(archiveRef, aptData);

        await remove(aptRef);
      } else {
        console.log('No data available to archive.');
      }
    } catch (error) {
      console.error('Failed to archive the appointment:', error);
    }
  };

  return (
    <BusinessSettingsContext.Provider
      value={{
        addEditAptService,
        aptServicesActive,
        categories,
        addOns,
        getAptCategories,
        changeCategoriesRanks: changeAptRanks,
        getAptDetails,
        editAptStaffMembers,
        addEditCategory,
        addEditAddOn,
        getCategory,
        getAddOn,
        editAptCategories,
        archiveAptService,
      }}
    >
      {children}
    </BusinessSettingsContext.Provider>
  );
};

export const useBusinessSettings = () => useContext(BusinessSettingsContext);
