import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, database } from '../../book/src/firebase';
import SpinnerOverlay from '../SpinnerOverlay';
import { ref, get, onValue, off } from 'firebase/database';
import ScheduleManager from 'src/book/src/data/ScheduleManager';
import { useAuth } from './AuthContext';
import Utils from 'src/book/src/data/Utils';
import { useLanguage } from 'src/LanguageContext';

const CustomersContext = createContext();

function aggregateByUid(events) {
  return events.reduce((acc, currentEvent) => {
    if (!currentEvent.uid) {
      return acc;
    }
    if (!acc[currentEvent.uid]) {
      acc[currentEvent.uid] = [];
    }
    acc[currentEvent.uid].push(currentEvent);

    return acc;
  }, {});
}

export const EventsProvider = ({ children }) => {
  const [pastEvents, setPastEvents] = useState(null);
  const [currentEvents, setCurrentEvents] = useState(null);

  const [currentEventsByUser, setCurrentEventsByUser] = useState(null);
  const [pastEventsByUser, setPastEventsByUser] = useState(null);

  const [staffInfo, setStaffInfo] = useState(null);
  const [failedUsers, setFailedUsers] = useState({});

  const { bizId, accessType, user } = useAuth();
  const { language } = useLanguage();
  const loading = false;

  const enrichEvents = (events) => {
    return events.map((event) => {
      const startFormatted = Utils.formatUnixTimestamp(event.startTimestamp, language);
      return {
        ...event,
        startFormatted: startFormatted,
      };
    });
  };

  React.useEffect(() => {
    const fetchFailedUsers = () => {
      const dbRef = ref(database, `/biz/failedUsers/${bizId}`);

      // Setting up the onValue listener
      onValue(
        dbRef,
        (snapshot) => {
          try {
            if (snapshot.exists()) {
              const info = snapshot.val();

              setFailedUsers(info);
              const xx = 'sd';

              // setStaffCalendarInfo(info);

              // setSelectedDays(info[selectedStaff]['workingHours']);
              // setScheduleData(info[selectedStaff]['specificOOOHours']);
            }
          } catch (error) {
            console.error(error);
          }
        },
        {
          onlyOnce: false,
        },
      );
    };

    if (bizId) {
      fetchFailedUsers();
    }

    // Cleanup function to remove the listener when the component unmounts or bizId changes
    return () => {
      const dbRef = ref(database, `/biz/failedUsers/${bizId}`);
      off(dbRef, 'value');
    };
  }, [bizId, accessType]);

  React.useEffect(() => {
    const fetchStaffInfo = () => {
      const dbRef = ref(database, `/biz/configs/${bizId}/staff`);

      // Setting up the onValue listener
      onValue(
        dbRef,
        (snapshot) => {
          try {
            if (snapshot.exists()) {
              const info = snapshot.val();
              const daysOfWeek = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ];

              for (const day of daysOfWeek) {
                for (const staff of Object.keys(info)) {
                  if (!info[staff]['workingHours']) {
                    info[staff]['workingHours'] = {};
                  }
                  if (!info[staff]['workingHours'][day]) {
                    info[staff]['workingHours'][day] = [];
                  }
                }
              }

              const yestrday = new Date();
              yestrday.setUTCHours(0, 0, 0, 0);
              yestrday.setDate(yestrday.getDate() - 1);

              for (const staff of Object.keys(info)) {
                if (!info[staff]['specificOOOHours']) {
                  info[staff]['specificOOOHours'] = {};
                } else {
                  info[staff]['specificOOOHours'] = Object.keys(
                    info[staff]['specificOOOHours'],
                  ).reduce((acc, timestamp) => {
                    const date = new Date(parseInt(timestamp));

                    const formattedDate = `${date.getUTCDate().toString().padStart(2, '0')}/${(
                      date.getUTCMonth() + 1
                    )
                      .toString()
                      .padStart(2, '0')}/${date.getUTCFullYear()}`;

                    if (info[staff]['specificOOOHours'][timestamp].closed) {
                      acc[formattedDate] = [];
                      return acc;
                    }

                    if (date <= yestrday || !info[staff]['specificOOOHours'][timestamp]) {
                      return acc;
                    }

                    acc[formattedDate] = info[staff]['specificOOOHours'][timestamp].map((slot) => ({
                      start: slot.start,
                      end: slot.end,
                    }));

                    return acc;
                  }, {});
                }

                // enrich apt options ids

                info[staff].aptOptionsIds = Object.keys(info[staff].aptOptions);
              }

              const selectedStaff =
                Object.entries(info).find(([key, value]) => value.uid === user.uid) === undefined
                  ? Object.entries(info)[0][0]
                  : Object.entries(info).find(([key, value]) => value.uid === user.uid)[0];

              info['selectedStaff'] = selectedStaff;

              setStaffInfo(info);

              // setStaffCalendarInfo(info);

              // setSelectedDays(info[selectedStaff]['workingHours']);
              // setScheduleData(info[selectedStaff]['specificOOOHours']);
            }
          } catch (error) {
            console.error(error);
          }
        },
        {
          onlyOnce: false,
        },
      );
    };

    if (bizId) {
      fetchStaffInfo();
    }

    // Cleanup function to remove the listener when the component unmounts or bizId changes
    return () => {
      const dbRef = ref(database, `/biz/configs/${bizId}/staff`);
      off(dbRef, 'value');
    };
  }, [bizId, accessType]);

  useEffect(() => {
    if (bizId) {
      // Define path references for current and past events
      const currentEventsRef = ref(database, `/events/biz/${bizId}`);
      const pastEventsRef = ref(database, `/events/past/biz/${bizId}`);

      const currentEventListener = onValue(
        currentEventsRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const currentEventsData = ScheduleManager.flattenEvents(
              snapshot.val(),
              'upcomingEvent',
            );
            const enrichedCurrrentEvents = enrichEvents(currentEventsData);
            const currenEventsAggregatedEnriched = aggregateByUid(enrichedCurrrentEvents);

            setCurrentEvents(currentEventsData);
            setCurrentEventsByUser(currenEventsAggregatedEnriched);
          } else {
            setCurrentEvents([]);
            setCurrentEventsByUser({});
          }
        },
        { onlyOnce: false },
      );

      const pastEventListener = onValue(
        pastEventsRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const pastEventsData = ScheduleManager.flattenEvents(snapshot.val(), 'pastEvent');
            const enrichedPastEvents = enrichEvents(pastEventsData);
            const pastEventsAggregated = aggregateByUid(enrichedPastEvents);

            setPastEvents(pastEventsData);
            setPastEventsByUser(pastEventsAggregated);
          } else {
            setPastEvents([]);
            setPastEventsByUser({});
          }
        },
        { onlyOnce: false },
      );

      return () => {
        off(currentEventsRef, 'value', currentEventListener);
        off(pastEventsRef, 'value', pastEventListener);
      };
    }
  }, [bizId, database]);

  const getAptStaffMembers = (aptId) => {
    const aptStaffMembers = [];

    if (!staffInfo || !aptId) return [];

    for (const staffKey in staffInfo) {
      if (staffKey === 'selectedStaff') continue;

      const staff = staffInfo[staffKey];
      if (staff.aptOptionsIds && staff.aptOptionsIds.includes(aptId)) {
        // this is how we remove staff:
        if (staff?.aptOptions[aptId]?.isHidden === true) {
          continue;
        }

        aptStaffMembers.push({
          id: staffKey,
          title: staff.title,
          color: staff.color ? staff.color : 'blue',
        });
      }
    }

    return aptStaffMembers;
  };

  const getAllStaffMembers = () => {
    const aptStaffMembers = [];

    if (!staffInfo) return [];

    for (const staffKey in staffInfo) {
      if (staffKey === 'selectedStaff') continue;

      const staff = staffInfo[staffKey];
      aptStaffMembers.push({
        id: staffKey,
        title: staff.title,
        color: staff.color ? staff.color : 'blue',
      });
    }

    return aptStaffMembers;
  };

  // const [pastEvents, setPastEvents] = useState(null);
  // const [currentEvents, setCurrentEvents] = useState(null);

  // const [currentEventsByUser, setCurrentEventsByUser] = useState(null);
  // const [pastEventsByUser, setPastEventsByUser] = useState(null);

  // const [staffInfo, setStaffInfo] = useState(null);

  return (
    <CustomersContext.Provider
      value={{
        pastEvents,
        currentEvents,
        currentEventsByUser,
        pastEventsByUser,
        staffInfo,
        failedUsers,
        getAptStaffMembers,
        getAllStaffMembers,
      }}
    >
      {!loading ? children : <SpinnerOverlay />}
    </CustomersContext.Provider>
  );
};

export const useEvents = () => useContext(CustomersContext);
