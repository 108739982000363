import parsePhoneNumberFromString from 'libphonenumber-js';
import SentryLogger from 'src/components/logging/SentryLogger';
import { translate } from 'src/translate';
import { DateTime, Interval } from 'luxon';

export default class Utils {
  static convertOOOHoursToWorkingHours(dailyWorkingHours) {
    dailyWorkingHours.sort((a, b) => a.start.localeCompare(b.start));

    const oooHours = [];
    let prevEnd = '00:00'; // Start of day

    dailyWorkingHours.forEach((interval, index) => {
      if (prevEnd < interval.start) {
        oooHours.push({ start: prevEnd, end: interval.start });
      }
      prevEnd = interval.end;

      if (index === dailyWorkingHours.length - 1 && prevEnd < '23:59' && prevEnd !== '00:00') {
        oooHours.push({ start: prevEnd, end: '23:59' });
      }
    });

    // if (dailyWorkingHours.length === 0) {
    //   oooHours.push({ start: "00:00", end: "23:59" });
    // }

    return oooHours;
  }

 

  // formats timestamp to dddd
  static formatMillis(time, language) {
    try {
      if (!time) {
        return ' ';
      }
      if (/^\d+$/.test(time)) {
        // If it's a valid number in string format, convert it to a number
        time = Number(time);
      }

      const date = new Date(time);

      // Options to include day of the week, year, month, day, and time
      const options = {
        weekday: 'long', // Adds the day of the week
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit', // Adds hour
        minute: '2-digit', // Adds minute
        hour12: false, // Uses 24-hour format
      };

      // Formatting the date to include day names in English with specified format and time
      const formattedDate = new Intl.DateTimeFormat(language + `-GB`, options).format(date);
      return formattedDate;
    } catch (error) {
      return ' ';
    }
  }

  /**
   * Retrieves the appropriate text based on the specified language, or defaults if the language is not available.
   * It checks both 'strings' and 'title' properties in the localetext object.
   *
   * @param {object} localetext - An object containing localized text data.
   * @param {string} language - The desired language for the text.
   * @returns {string} The localized text in the specified language or a default.
   */
  static getLanguageOrDefault(localetext, language) {
    try {
      if (!localetext) {
        return '';
      }

      // Determine whether to use 'strings' or 'title' based on what is available in the object.
      let textContainer = localetext.strings || localetext.title;
      if (!textContainer) {
        return '';
      }

      // Attempt to retrieve the text for the specified language.
      let text = textContainer[language];

      // If text is found for the given language, return it.
      if (text) {
        return text;
      }

      // If the specified language is not available, use the default locale.
      if (localetext.defaultLocal) {
        return textContainer[localetext.defaultLocal];
      } else {
        // If no default locale is specified, return the first available text.
        return textContainer[Object.keys(textContainer)[0]];
      }
    } catch (error) {
      // Log the error if there is a failure in retrieving the text.
      const logger = new SentryLogger();
      logger.error(error);
      return '';
    }
  }

  static hasTimePassed(isoTime) {
    const { DateTime } = require('luxon');

    const startTime = DateTime.fromISO(isoTime);
    const now = DateTime.utc();

    return now > startTime;
  }

  static validatePhoneNumber(number) {
    const phoneNumber = parsePhoneNumberFromString(number);
    if (phoneNumber && phoneNumber.isValid()) {
      return true;
    } else {
      return false;
    }
  }

  static filterOutHiddenTags = (tags, hiddenTagsKeys) => {
    if (!tags) return [];
    const filteredTags = tags.filter((tag) => {
      const tagKey = Object.keys(tag)[0];

      if (hiddenTagsKeys[tagKey]) return false;
      return true;
    });
    return filteredTags;
  };

  static formatUnixTimestamp(unixTimestamp, language) {
    if (!language) return 'Error choose lang';

    if (!unixTimestamp) return unixTimestamp;
    const { DateTime } = require('luxon');

    const now = DateTime.now().startOf('day');
    const inputDate = DateTime.fromMillis(parseInt(unixTimestamp));

    const inputDateStart = inputDate.startOf('day');
    const diffDays = inputDateStart.diff(now, 'days').days;

    // Time formatting part
    const timeFormat = inputDate.toFormat('HH:mm');

    if (diffDays === 0) {
      return translate('today', language) + ' ' + timeFormat;
    } else if (diffDays === 1) {
      return translate('tomorrow', language) + ' ' + timeFormat;
    } else if (diffDays > 1 && diffDays < 7) {
      return inputDate.toFormat(`cccc HH:mm`, { locale: language });
    } else {
      return inputDate.toFormat(`dd/MM/yyyy HH:mm`);
    }
  }

  // helper function that converts start and end time to a grid row span..
  static calculateGridRow(startTime, endTime) {
    // Adjust this function to calculate minutes since 10:00 AM instead of 12:00 AM
    const timeToMinutes = (time) => {
      let [hours, minutes] = time.split(':').map(Number);
      if (minutes === 29) {
        minutes = 30;
      }
      if (minutes === 59) {
        hours = hours + 1;
        minutes = 0;
      }
      // Adjust the hours to account for the new start time of 10:00 AM
      // hours -= 10; // Subtract 10 hours to align times with the new start time of 10:00 AM
      if (hours < 0) hours = 0; // Ensure hours don't go negative
      if (hours === 0) hours = 24;
      return hours * 60 + minutes;
    };

    const startMinutes = startTime === '00:00' ? 0 : timeToMinutes(startTime);
    const endMinutes = timeToMinutes(endTime);

    // Since we're starting at 10 AM, which is equivalent to skipping the first 20 rows
    // (10 hours * 2 (for 30-minute intervals) * 6 rows per interval),
    // the starting row is not 2 but rather 22.
    const startRow = (startMinutes / 30) * 6 + 2; // Adjusted start row for 10 AM start

    let span = ((endMinutes - startMinutes) / 30) * 6;
    // if (span < 0) {
    //     span = span * -1;
    // }

    return `${startRow} / span ${span}`;
  }

  static getCurrencySymbol(currencyCode) {
    try {
      const formatter = new Intl.NumberFormat('en', { style: 'currency', currency: currencyCode });
      // Extract the symbol from a dummy number
      return formatter.format(0).replace(/\d/g, '').trim();
    } catch (e) {
      const logger = new SentryLogger();
      logger.error(e);

      return currencyCode;
    }
  }

  static convertUTCmillisToJerusalem(utcMillis) {
    if (!utcMillis) return null;

    // Ensure utcMillis is a number, convert if it's a string
    const millis = typeof utcMillis === 'string' ? parseInt(utcMillis, 10) : utcMillis;

    // Create a DateTime object from UTC millis
    const utcDateTime = DateTime.fromMillis(millis, { zone: 'utc' });

    // Convert the DateTime object to Asia/Jerusalem timezone
    const jerusalemDateTime = utcDateTime.setZone('Asia/Jerusalem');

    // Get the equivalent time in milliseconds for the Asia/Jerusalem timezone
    return jerusalemDateTime.toMillis();
  }

  static findIntersections(events) {
    events.forEach((event) => {
      event.intersections = [];
      event.maxConcurrency = 0; // New property to store the maximum concurrency
    });

    // We use the Luxon library, assuming it is included in your project
    const { DateTime, Interval } = require('luxon');

    for (let i = 0; i < events.length; i++) {
      const eventA = events[i];
      const intervalA = Interval.fromDateTimes(
        DateTime.fromISO(eventA.startISO),
        DateTime.fromISO(eventA.endISO),
      );

      // Store all overlapping intervals
      let overlapPeriods = [];

      for (let j = 0; j < events.length; j++) {
        if (i !== j) {
          const eventB = events[j];
          const intervalB = Interval.fromDateTimes(
            DateTime.fromISO(eventB.startISO),
            DateTime.fromISO(eventB.endISO),
          );

          // Check for overlap and store the overlapping interval
          if (intervalA.overlaps(intervalB)) {
            eventA.intersections.push(eventB.eventId);
            overlapPeriods.push(intervalA.intersection(intervalB));
          }
        }
      }

      // Calculate the maximum number of concurrent events during any part of eventA
      if (overlapPeriods.length > 0) {
        eventA.maxConcurrency = Utils.calculateMaxConcurrency(overlapPeriods);
      }
    }

    return events;
  }

  static findMaxConcurrency(events) {
    let max = 0;

    for (let i = 0; i < events.length; i++) {
      if (events[i].maxConcurrency > max) max = events[i].maxConcurrency;
    }
    return max + 1;
  }

  // Helper function to calculate maximum concurrency from a list of intervals
  static calculateMaxConcurrency(intervals) {
    let times = [];

    // Create a list of all start and end times marked as 'begin' or 'end'
    intervals.forEach((interval) => {
      times.push({ time: interval.start, type: 'begin' });
      times.push({ time: interval.end, type: 'end' });
    });

    // Sort by time; if times are equal, 'end' events come before 'begin' events to correctly handle coincident intervals
    times.sort((a, b) => a.time - b.time || (a.type === 'begin' ? 1 : -1));

    let maxConcurrency = 0;
    let currentConcurrency = 0;

    // Sweep line algorithm to calculate max concurrency
    times.forEach((timeEvent) => {
      if (timeEvent.type === 'begin') {
        currentConcurrency++;
        maxConcurrency = Math.max(maxConcurrency, currentConcurrency);
      } else {
        currentConcurrency--;
      }
    });

    return maxConcurrency;
  }

  static diffInMinutes(startTimeIso, endTimeIso) {
    // Check for null or undefined inputs
    if (startTimeIso == null || endTimeIso == null) {
      return 0;
    }

    // Convert ISO strings to DateTime objects
    const startTime = DateTime.fromISO(startTimeIso);
    const endTime = DateTime.fromISO(endTimeIso);

    // Validate that the DateTime objects are valid
    if (!startTime.isValid || !endTime.isValid) {
      return 0;
    }

    // Calculate the difference in minutes
    return endTime.diff(startTime, 'minutes').minutes;
  }

  static classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
}
